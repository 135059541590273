.chat-searchbox {
    border: 0 !important;
    border-bottom: 1px solid #999 !important;
    border-radius: 0 !important;
}

.bg-chat-system {
    background-color: #d4e1ff;
}

[data-bs-theme=dark] .bg-chat-system {
    background-color: #192648;
}

.chat-contact-item {
    cursor: pointer;
    padding: 0%;
}

.chat-contact-item:hover {
    background-color: #f2f2f2;
}

[data-bs-theme=dark] .chat-contact-item:hover {
    background-color: #101114;
}

.chat-contact-item-selected {
    background-color: #d4e9f4 !important;
}

[data-bs-theme=dark] .chat-contact-item-selected {
    background-color: #101114 !important;
}

.chat-contact-title {
    max-width: 110px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-contact-subtext {
    max-width: 140px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-seenlist-name {
    max-width: 140px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat_messenger_body {
    background-image: url("/public/assets/media/bg/chatbg/light.jpg");
    background-repeat: repeat;
    position: relative;
}

[data-bs-theme=dark] .chat_messenger_body {
    background-image: url("/public/assets/media/bg/chatbg/dark.jpg");
    background-repeat: repeat;
    position: relative;
}

.btn-chat-to-end {
    position: absolute;
    bottom: 30px;
    right: calc(50% - 25px);
    background-color: #376797 !important;
    opacity: .5;
}

.btn-chat-to-end i {
    color: #fff !important;
}

.btn-chat-to-end:hover,
.btn-chat-to-end:active {
    background-color: #376797 !important;
    opacity: .9;
}

.btn-chat-to-end-newmsg {
    position: absolute;
    bottom: 75px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    right: calc(50% - 12px);
    line-height: 24px;
    font-size: 12px;
    color: #fff;
    background-color: var(--bs-red);
}

.chat-message-text {
    width: 60%;
    max-width: 90%;
}

.chat-message-footer {
    border-top: .01rem solid #ccc;
    margin-top: 8px;
    padding-top: 2px;
}

.chat-typing-user-name {
    margin-right: 2px;
}


.chat-job-card {
    border: 1px solid #2B2B3C0D;
    margin-bottom: 5px;
    padding: 10px;
}

.chat-badge-icon {
    background-color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
    color: #379777;
}