
.job-dashboard-jobs-div {
    z-index: 15;
    margin-bottom: 16px;
}
.job-dashboard-status-title {
    opacity: .5;
    z-index: 20;
    position: relative;
}
.job-dashboard-status-title i {
    font-size: 22px;
}
.job-dashboard-status-title span {
    font-size: 24px;
    font-weight: 200;
}

.table-jobs td {
    vertical-align: middle;
}

.table-jobs tr.border-bottom {
  border-bottom: 1px solid #e2e2e2!important;
}


  
  .job-dashboard-flag {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    line-height: 16px;
    color: #fff;
    text-align: center;
  }
  .job-dashboard-addflag {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    line-height: 12px;
    border: #666 dashed 1px;
    color: #999;
    text-align: center;
  }
  
  .job-extra-actions {
    display: none;
  }
  
  .job-actions-col {
    white-space: nowrap;
    overflow: visible;
    min-width: 170px !important;
    width: 170px !important;
    max-width: 170px !important;
  }
  
  .job-actions-col-big {
    min-width: 270px !important;
    width: 270px !important;
    max-width: 270px !important;
  }
  
  .job-actions-parent {
    background-color: #fff;
  }
  .job-actions-parent:hover{
    background-color: #F5F8FA;
  }