.calls-reports-actions a i {
    font-size: 16px;
    color:#555
  }

  .call-labels .badge {
    font-weight: 500!important;
    padding: .4em .6em!important;
    font-size: 10px;
    margin-right: 1px;
    margin-left: 1px;
  }

  audio.recording { 
      position: absolute;
      right: 0;
      top: 0;
  }

  .recording-layer {
      position:absolute;
      border-radius: 25px;
      text-align: center;
      right: 0;
      top: 0;
      padding-top: 25px;
      width: 300px;
      background-color: #F5F8FA;
      z-index: 999999;
  }

  div.callname{
      font-size: 12px;
  }
  
  div.callnumber{
      font-size: 10px;
      color: #999;
  }

  .timeline-label .timeline-label {
      width: 75px!important;
  }
  .timeline-label:before {
      left: 76px!important;
  }

  .event-text {
      font-weight: normal;
      font-size: 12px;
  }
