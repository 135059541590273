[data-bs-theme=light] .filtering {
    background-color: #ebeef0;
}

[data-bs-theme=dark] .filtering {
    background-color: #313239;
}

.filtering input:not(.input-range-numbers) {
    background-color: transparent !important;
    border: 0 none !important;
}

.input-range-numbers {
    text-align: center;
    width: 60px;
}

.filter-group {
    background-color: #e1dfdf;
    padding: 4px;
    border-radius: 5px;
}

[data-bs-theme=dark] .filter-group {
    background-color: #1B1C22;
}

.filter-group i {
    color: #666 !important;
}

.filter-options {
    background-color: #FFF;
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    width: 200px;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 34px;
}

[data-bs-theme=dark] .filter-options {
    background-color: #1B1C22;
    border-left: 1px solid #1B1C22;
    border-right: 1px solid #1B1C22;
}

.filter-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
}

[data-bs-theme=dark] .filter-item {
    border-bottom: 1px solid #1B1C22;
}

.filter-item-selected {
    background-color: #EEE;
}

[data-bs-theme=dark] .filter-item-selected {
    background-color: #1B1C22;
}

.filter-item:hover {
    background-color: #EEE;
}

[data-bs-theme=dark] .filter-item:hover {
    background-color: #40414a;
}



.filter-operations {
    width: 50px;
}

.filter-op-item {
    text-align: center;
}