.table-roles {
    max-width: 800px;
}
.table-roles th, .table-roles td {
    vertical-align: middle;
}
.table-roles th:not(:first-child), .table-roles td:not(:first-child) {
    text-align: center;
}
.table-roles th:first-child, .table-roles td:first-child {
    padding-left: 20px!important;
}
.table-roles .action-col {
    min-width: 100px;
    width: 150px;
}
.table-roles .form-check {
    display: inline-block;
}

.form-check.form-check-solid.form-check-danger .form-check-input:checked {
    background-color: #f1416c!important;
}