.filtering-range-slider {
    margin-right: 20px;
}

.data-row.selected,
.data-row.selected:hover>* {
    background-color: #d8dadb !important;
    --bs-table-accent-bg: #d8dadb !important;
}


[data-bs-theme=dark] .data-row.selected,
[data-bs-theme=dark].data-row.selected:hover>* {
    background-color: #1B1C22 !important;
    --bs-table-accent-bg: #1B1C22 !important;
}

.data-table-hover-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    z-index: 99;
}

.data-table-hover-loading * {
    color: #fff !important;
    font-size: 18px;
}


.data-table-bar a {
    color: #999;
}

.data-table-bar a.active {
    color: #444;
}

.data-table-bar a.disabled,
.data-table-bar a.disabled:hover {
    color: #CCC;
    cursor: default;
}

.data-table-bar a:hover {
    color: #000;
}

[data-bs-theme=dark] .data-row:hover td {
    background-color: #16181c !important;
    --bs-table-accent-bg: #16181c !important;
    --bs-table-bg-state: #16181c !important;
}

tr.data-table-selected,
tr.data-table-selected:hover,
tr.data-table-selected:hover td {
    background-color: #EAF3FF !important;
    --bs-table-accent-bg: #EAF3FF !important;
}

[data-bs-theme=dark] tr.data-table-selected,
[data-bs-theme=dark] tr.data-table-selected td {
    background-color: #0F1014 !important;
    --bs-table-accent-bg: #0F1014 !important;
    --bs-table-bg-state: #0F1014 !important;
}

[data-bs-theme=dark] tr.data-table-selected:hover,
[data-bs-theme=dark] tr.data-table-selected:hover td {
    background-color: #08080a !important;
    --bs-table-accent-bg: #08080a !important;
    --bs-table-bg-state: #08080a !important;
}


.table.table-row-dashed tr.data-table-selected {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: #c2cbd6;
}

[data-bs-theme=dark] .table.table-row-dashed tr.data-table-selected {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: #0F1014;
}



.multi-action-row {
    background-color: #f9f9f9 !important;
}



[data-bs-theme=dark] .multi-action-row {
    background-color: #0F1014 !important;
}