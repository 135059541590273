.modal-title {
    font-weight: 200;
}


.modal-dialog {
    max-width: 650px!important;
    margin: 1.75rem auto;
}

.modal-lg {
    max-width: 900px!important;
}

.modal-xl {
    max-width: 1400px!important;
}

.modal-xxl {
    max-width: 1800px!important;
}

@media (max-width: 1450px) {
    .modal-xl {
        max-width: 85%!important;
    }
    .modal-xxl {
        max-width: 95%!important;
    }   
}