.general-search-container {
    position: absolute;
    max-height: 400px;
    width: 100%;
    border-radius: .475rem;
    background-color: #fff;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
}

[data-bs-theme=dark] .general-search-container {
    background-color: #1b1b28 !important;
}


.search-item-container {
    padding: 10px 15px;
}

.search-item-container:not(:last-child) {
    border-bottom: 1px solid #ccc;
}