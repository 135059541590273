.card-ext-secondary {
    border-left: 10px solid #A1A5B7;
}
.card-ext-success {
border-left: 10px solid var(--bs-active-success);
}
.card-ext-danger {
border-left: 10px solid var(--bs-active-danger);
}
.card-ext-warning {
border-left: 10px solid var(--bs-active-warning);
}