.message-to-job-float {
    background-color: white;
    position: fixed;
    right: 20px;
    top: 10px;
    z-index: 2500;
    width: 500px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
}

[data-bs-theme=dark] .message-to-job-float {
    background-color: #1C1D22;
}


.message-to-job-text {
    margin-top: 16px;
    white-space: pre-line;
    margin-bottom: 16px;
    height: 350px;
    overflow-y: scroll;
}