.ringing {
    animation: shake 0.1s;
    animation-iteration-count: infinite;
    }
    
    @keyframes shake {
      0% { transform: rotate(0deg); }
      25% { transform: rotate(15deg); }
      50% { transform: rotate(0eg); }
      75% { transform: rotate(-10deg); }
      100% { transform: rotate(0deg); }
    }
  
    .agent-header-pie{
      width: 50px !important;
      height: 50px !important;
    }
  
    .latestcall-avatar {
      border-radius: 4px;
    }
  
    #chart-today-activity{
      height: 120px;
    }
  
    .live-action-link {
      color: #999;
    }
  
    .avatar-talking-time {
      width: 100%;
      text-align: center;
      margin-top: -10px;
      z-index: 5;
    }
  
    .card-agentmap .force-logout {
      display: none;
    }
    .card-agentmap:hover .force-logout {
      display: inline-block;
    }
  
    .card-agentmap-secondary {
      border-left: 10px solid #A1A5B7;
    }
    .card-agentmap-success {
      border-left: 10px solid var(--bs-active-success);
    }
    .card-agentmap-danger {
      border-left: 10px solid var(--bs-active-danger);
    }
    .card-agentmap-warning {
      border-left: 10px solid var(--bs-active-warning);
    }
  
