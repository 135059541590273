.aside-menu {}

.aside-menu .menu-item {
    position: relative;
}

.aside-menu .menu-item .aside-menu-badge {
    position: absolute;
    top: 10px;
    right: 20px;
}

.aside-menu .menu-item .aside-menu-badge .badge {
    width: 18px;
    height: 18px;
    font-weight: 600;
    font-size: 10px;
}