

  
  @media (min-width: 992px) {
    .content {
        padding: 20px 0;
    }
    .chart-title {
      font-size: 28px;
    }

  }

  @media (min-width: 1400px) {
    .chart-title {
      font-size: 34px;
    }
  }
  