fieldset {
  display: block;
  padding: 20px;
  margin: 0;
  border-width: 1px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
  border-radius: 15px;
  position: relative;
}

fieldset caption {
  position: absolute;
  font-size: 12px;
  line-height: 20px;
  padding: 0 6px;
  margin: 0;
  top: -10px;
  left: 20px;
  background-color: #fff;
}


@keyframes blink {

  0%,
  80%,
  100% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }
}

.blinking-text {
  animation: blink 1.5s linear infinite;
}

.menu-mobile-side * {
  color: #fff !important;
}

.menu-mobile-side .menu-link:hover {
  background-color: #1b1b28 !important;
  color: #fff !important;
}

@media (min-width: 600px) {
  .aside {
    width: 100px !important;
  }

  .aside-enabled.aside-fixed.header-fixed .header {
    left: 100px !important;
  }

  .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    left: 100px !important;
  }

  .aside-enabled.aside-fixed .wrapper {
    padding-left: 100px !important;
  }
}


.bg-white {
  background-color: #fff !important;
}

.icon-button:hover {
  background-color: #f2f2f2;
}

.menu.bg-dark a.menu-link {
  color: #9899ac
}

.menu.bg-dark a.menu-link:hover {
  background-color: #1b1b28;
  color: #fff;
}

.min-200 {
  min-width: 200px !important;
}

.min-150 {
  min-width: 150px !important;
}

.min-125 {
  min-width: 125px !important;
}

.min-100 {
  min-width: 100px !important;
}

.min-75 {
  min-width: 75px !important;
}

.min-50 {
  min-width: 50px !important;
}

.max-200 {
  max-width: 200px !important;
}

.max-150 {
  max-width: 150px !important;
}

.max-125 {
  max-width: 125px !important;
}

.max-100 {
  max-width: 100px !important;
}

.max-75 {
  max-width: 75px !important;
}

.max-50 {
  max-width: 50px !important;
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}

.noscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.signup-aside {
  background-color: #F2C98A
}

.signup-aside-text {
  color: #986923;
}

.bg-light-danger,
.badge-light-danger {
  background-color: #fddee7 !important;
}

.bg-light-success,
.badge-light-success {
  background-color: #d5fce8 !important;
}

[data-bs-theme=light] .form-control-solid,
.form-select-solid {
  background-color: #eee !important;
  border-color: #eee !important;
}

.datatable-head .form-floating>.form-select {
  padding-top: .55rem !important;
  padding-bottom: .55rem !important;
  height: calc(2.75rem + 2px);
  min-height: calc(2.75rem + 2px);
  line-height: 1.25;
}

.datatable-head .form-floating>label {
  padding: 0 !important;
  height: auto !important;
  background-color: #fff;
  top: -1px;
  left: 2px;
  font-size: 11px;
  opacity: 1;
  transition: none;
}

[data-bs-theme=dark] .datatable-head .form-floating>label {
  background-color: #1C1D22;
}

.mobile-menu-drawer {
  background-color: #1C1D22;
}

.form-error {
  font-size: 10px;
  display: none;
  font-weight: normal;
}

[data-bs-theme=light] .form-check.form-check-solid .form-check-input {
  background-color: #e1e1e1 !important;
}

[data-bs-theme=dark] .form-check.form-check-solid .form-check-input {
  background-color: #484951 !important;
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: #009ef7 !important;
}

.calendar-loading {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  padding-top: 200px;
}

.legend-hidden {
  opacity: .3 !important;
}

.tagify-item-inactive * {
  color: #CCC;
}

.aside-user-menu {
  z-index: 105;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(210px, 122px);
}

.image-input.image-input-outline .image-input-wrapper {
  border: 0 none;
}

.card-action {
  display: flex;
  align-items: center;
}

.card-action button,
.card-footer-form button {
  margin-right: 10px;
}

.avatar-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, .4);
  color: #fff;
  padding-top: 50px;
}

.app-loader {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
}

.app-loader i {
  font-size: 30px;
  color: #333;
}

.app-loader h2 {
  font-size: 22px;
}

.indicator-btn i {
  font-size: 18px;
}

.fa-spin {
  padding: 0 !important;
  margin: 0 !important;
  -webkit-animation: fa-spin .5s infinite linear;
  animation: fa-spin .5s infinite linear;
}

.big-icon i {
  font-size: 128px;
}

.textbox-inline-style-old {
  background-color: transparent !important;
  border: none;
  outline: none;
}

.textbox-large-text {
  font-size: 24px;
}

.textbox-medium-text {
  font-size: 16px;
}

input.is-invalid::-webkit-input-placeholder {
  color: red !important;
  opacity: .7;
}

input.is-invalid:-moz-placeholder {
  color: red !important;
  opacity: .7;
}

input.is-invalid::-moz-placeholder {
  color: red !important;
  opacity: .7;
}

input.is-invalid:-ms-input-placeholder {
  color: red !important;
  opacity: .7;
}

.toast-body {
  font-size: 12px !important;
}

#kt_docs_toast_stack_container {
  z-index: 9999999999999 !important;
  bottom: 50px;
}

#kt_docs_toast_stack_container .toast {
  margin-bottom: 8px;
}


.menu-link {
  font-size: 12px;
}

.menu-link:hover {
  background-color: #ededed;
}

.aside-menu .menu-link:hover {
  background-color: #131417 !important;
}

[data-bs-theme=dark] .menu-link:hover {
  background-color: #131417;
}

.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) i {
  /* transition: color .2s ease, background-color .2s ease;
  background-color: #f1faff;
  color: #009ef7; */
}


.form-check-indeterminate {
  background-color: #86c7ec !important;
}

.aside-footer {
  position: absolute;
  bottom: 0;
  color: #ddd;
  font-size: 11px;
}

.tooltip-inner {
  max-width: 500px !important;
}

.float-audio-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #1a1a27;
}


.btn-agent-ext {
  font-size: 10px !important;
  padding: 2px 4px !important;
}


.btn-multiple-date {
  font-size: 10px !important;
  padding: 3px 8px !important;
}

.badge-multiple-date {
  font-size: 10px !important;
  padding: 3px 8px !important;
  cursor: default !important;
  background-color: var(--bs-secondary);
  border: 0 none !important;
}

.badge-multiple-date-blink {
  background-color: #b4d1e1 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.badge-multiple-date-blink-off {
  background-color: var(--bs-secondary);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}


.dropdown-toggle::after {
  content: none !important;
}

.latest-call-link:hover {
  background-color: #f2f2f2;
}

.latest-call-link.selected {
  background-color: #f0f0f0;
}


.job-form-map-container {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #86c7ec;
  z-index: 0;
}


.address-field-container {
  position: relative;
}

.address-field-autocomplete {
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
}

.address-field-autocomplete-item {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
}

[data-bs-theme=dark] .address-field-autocomplete,
[data-bs-theme=dark] .address-field-autocomplete-item {
  background-color: #131417;
}

.address-field-autocomplete-item:hover,
.address-field-autocomplete-item.selected {
  background-color: #F1FAFF;
  color: #0095E8;
}

[data-bs-theme=dark] .address-field-autocomplete-item:hover,
[data-bs-theme=dark] .address-field-autocomplete-item.selected {
  background-color: #1f2023;
}



[data-bs-theme=light] .input-group-text {
  background-color: #ededed !important;
  border: 0 none !important;
}


.dropdown-menu-div {
  position: absolute;
  background-color: #fff;
  z-index: 10;
  top: 20px;
  max-height: 200px;
  min-width: 150px;
  overflow: scroll;
  -webkit-box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  display: none;
}

.dropdown-menu-div .dropdown-menu-item {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-menu-div .dropdown-menu-item:hover {
  background-color: #F1FAFF;
  color: #0095E8;
}


.badge-job-dashboard {
  position: absolute;
  padding: 0 !important;
  top: 0;
  right: 0;
}

.badge.badge-xs {
  font-size: .8rem;
  font-weight: normal;
  padding: 3px 6px;
}


.durationpicker-container {
  background-color: #eee !important;
  border-radius: .475rem;
}

.float-calendar-event-card {
  position: absolute;
  background-color: white;
  left: 0;
  top: 30px;
  min-width: 200px;
  height: 150px;
  padding: 20px;
  display: none;
  -webkit-box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 53px -16px rgba(0, 0, 0, 0.75);
}



.job-note-blink {
  background-color: #b4d1e1 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.job-note-blink-off {
  background-color: inherit;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.badge-xs {
  font-size: 11px;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: 0.45rem 0.85rem !important;
  font-size: .725rem;
  border-radius: 0.475rem;
}

.limited-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-commalist {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.table-commalist span:not(:last-child)::after {
  content: ',';
}

.tagify__dropdown,
.daterangepicker,
.select2-container--open {
  z-index: 1070 !important;
}

.tagify .tagify__tag .tagify__tag__removeBtn.tagify__tag__removeBtn_light {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") !important;
}

.reports-total-row,
.reports-total-row:hover,
.reports-total-row:hover tr,
.reports-total-row:hover td {
  background-color: #DDD !important;
  --bs-table-accent-bg: #DDD !important;
  --bs-table-bg-state: #DDD !important;
}


[data-bs-theme=dark] .reports-total-row,
[data-bs-theme=dark] .reports-total-row:hover,
[data-bs-theme=dark] .reports-total-row:hover tr,
[data-bs-theme=dark] .reports-total-row:hover td {
  background-color: #333 !important;
  --bs-table-accent-bg: #333 !important;
  --bs-table-bg-state: #333 !important;
}


.metroname-tooltip-small {
  font-size: 15px;
  font-family: "Encode Sans SC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  border: 0 none !important;
  color: #333 !important;
  background-color: transparent !important;
  opacity: .8 !important;
  box-shadow: none !important;
  padding: 0 10px !important;
}

.metroname-tooltip-large {
  font-size: 20px;
  font-family: "Encode Sans SC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  border: 0 none !important;
  color: #333 !important;
  background-color: transparent !important;
  opacity: .8 !important;
  box-shadow: none !important;
  padding: 0 10px !important;
}

.bg-light-secondary {
  background-color: #ecedf1;
}

.img-drp-flag {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin: 0 6px
}

.stickycard {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.select2-selection__rendered,
.form-inputs,
.tagify__input {
  color: #666 !important;
  font-size: 12px;
}

[data-bs-theme=dark] .select2-selection__rendered,
[data-bs-theme=dark] .form-inputs,
[data-bs-theme=dark] .tagify__input {
  color: #DDD !important;
  font-size: 12px;
}

tr.table-row-selected,
tr.table-row-selected:hover>* {
  background-color: #e4ebef !important;
  --bs-table-accent-bg: #e4ebef !important;
}

.line-break {
  white-space: pre-line !important;
}

.badge-dotted {
  background-color: #f2f2f2;
  border: 1px dashed #999;
  color: #666;
}


.menu-main-submenu .menu-item .menu-link:hover {
  border-radius: 0 !important;
  background-color: #eee !important;
}